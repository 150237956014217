var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(25, "Public holidays"),
        size: "xxl",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "cancel-variant": "outline-primary",
        "ok-variant": "primary",
        "cancel-title": _vm.FormMSG(35, "Close"),
        "ok-title": _vm.labelOkTitle,
      },
      on: {
        show: _vm.reloadData,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleClickOk.apply(null, arguments)
        },
        hidden: _vm.emitEventClose,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { staticClass: "text-center" },
            [
              _c(
                "b-button-group",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: `${
                          _vm.manageCalendar ? "outline-" : ""
                        }primary`,
                      },
                      on: { click: _vm.toggleManageCalendar },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(37, "Public holidays")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: `${
                          !_vm.manageCalendar ? "outline-" : ""
                        }primary`,
                      },
                      on: { click: _vm.toggleManageCalendar },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(38, "Manage calendars")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.manageCalendar
        ? _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("b-table", {
                        ref: "table-holidays",
                        staticStyle: { "text-align": "center" },
                        attrs: {
                          "selected-variant": "primary",
                          hover: true,
                          responsive: "sm",
                          selectable: "",
                          "select-mode": "single",
                          items: _vm.holidays,
                          fields: _vm.holidaysField,
                          "head-variant": "dark",
                          bordered: "",
                          small: "",
                          "show-empty": "",
                          "empty-text": _vm.FormMSG(125, "No holidays found"),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(date)",
                              fn: function (data) {
                                return [
                                  _c("div", [
                                    !data.item.forEdit
                                      ? _c("div", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  data.item.date,
                                                  "DD/MM/YYYY"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          [
                                            _c("v-date-picker", {
                                              attrs: {
                                                locale: _vm.lang,
                                                masks: _vm.masks,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      inputValue,
                                                      togglePopover,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                value:
                                                                  inputValue,
                                                                readonly: "",
                                                              },
                                                            }),
                                                            _c(
                                                              "b-input-group-append",
                                                              {
                                                                on: {
                                                                  click:
                                                                    togglePopover,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-input-group-text",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Calendar"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "#06263E",
                                                                          size: 16,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: data.item.date,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    data.item,
                                                    "date",
                                                    $$v
                                                  )
                                                },
                                                expression: "data.item.date",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(name)",
                              fn: function (data) {
                                return [
                                  _c("div", [
                                    !data.item.forEdit
                                      ? _c("div", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(data.item.name) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  214,
                                                  "Name"
                                                ),
                                              },
                                              model: {
                                                value: data.item.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    data.item,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "data.item.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(shortName)",
                              fn: function (data) {
                                return [
                                  _c("col", {
                                    staticStyle: { width: "100px" },
                                  }),
                                  _c("div", [
                                    !data.item.forEdit
                                      ? _c("div", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(data.item.shortName) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  215,
                                                  "Short name"
                                                ),
                                              },
                                              model: {
                                                value: data.item.shortName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    data.item,
                                                    "shortName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.item.shortName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(rate)",
                              fn: function (data) {
                                return [
                                  _c("col", {
                                    staticStyle: { width: "100px" },
                                  }),
                                  _c("div", [
                                    !data.item.forEdit
                                      ? _c("div", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(data.item.rate) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    step: "0.01",
                                                    min: "0",
                                                    placeholder: "0",
                                                  },
                                                  model: {
                                                    value: data.item.rate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        data.item,
                                                        "rate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "data.item.rate",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Percent"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            staticClass: "icon",
                                                            attrs: {
                                                              color: "#06263E",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(paid)",
                              fn: function (data) {
                                return [
                                  _c("div", [
                                    !data.item.forEdit
                                      ? _c("div", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                data.item.paidStr !== ""
                                                  ? data.item.paidStr
                                                  : "00:00"
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          [
                                            _c("input-date-time-picker", {
                                              attrs: {
                                                uiid: "paidIfNotWorked",
                                                type: "text",
                                                placeholder: "hh:mm",
                                                item: data.item.paidStr,
                                                error: null,
                                                nbHour:
                                                  _vm.rangeHour[
                                                    _vm.WORKTYPE.daily
                                                  ],
                                                nbMinute:
                                                  _vm.rangeMinute[
                                                    _vm.WORKTYPE.daily
                                                  ],
                                                isEditable: () => true,
                                                workType: _vm.WORKTYPE.daily,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handlePaifIfNotWorkChange(
                                                    $event,
                                                    data.index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(calNumber)",
                              fn: function (data) {
                                return [
                                  _c("div", [
                                    !data.item.forEdit
                                      ? _c("div", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                data.item.publicHolidayCalendar
                                                  .name
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                options: _vm.calendars,
                                                label: "name",
                                                reduce: (option) =>
                                                  option.calNumber,
                                                clearable: false,
                                                placeholder: _vm.FormMSG(
                                                  219,
                                                  "Select a calendar"
                                                ),
                                              },
                                              model: {
                                                value: data.item.calNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    data.item,
                                                    "calNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.item.calNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(color)",
                              fn: function (data) {
                                return [
                                  _c("div", [
                                    !data.item.forEdit
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "b-img",
                                              _vm._b(
                                                {
                                                  attrs: { rounded: "circle" },
                                                },
                                                "b-img",
                                                {
                                                  blank: true,
                                                  blankColor: data.item.color,
                                                  width: 20,
                                                  height: 20,
                                                  class: "m1",
                                                },
                                                false
                                              )
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c("verte", {
                                              attrs: {
                                                picker: "square",
                                                model: "hex",
                                                menuPosition: "right",
                                              },
                                              model: {
                                                value: data.item.color,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    data.item,
                                                    "color",
                                                    $$v
                                                  )
                                                },
                                                expression: "data.item.color",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(edit)",
                              fn: function (data) {
                                return [
                                  !data.item.forEdit
                                    ? _c("div", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn-transparent",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editHoliday(
                                                  data.item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(_vm.getLucideIcon("Edit"), {
                                              tag: "component",
                                              attrs: { color: "#225CBD" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _c("div", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn-transparent",
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateHoliday(
                                                  data.item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              _vm.getLucideIcon("CheckSquare"),
                                              {
                                                tag: "component",
                                                attrs: { color: "#3C7442" },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "cell(remove)",
                              fn: function (data) {
                                return [
                                  _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-transparent text-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delHoliday(data.item)
                                          },
                                        },
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Trash2"), {
                                          tag: "component",
                                          attrs: {
                                            size: 20,
                                            "stroke-width": 2,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1477052333
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("b-table", {
                        ref: "table-calendars-holidays",
                        staticStyle: { "text-align": "center" },
                        attrs: {
                          "selected-variant": "primary",
                          hover: true,
                          responsive: "sm",
                          selectable: "",
                          "select-mode": "single",
                          items: _vm.calendars,
                          fields: _vm.calendarsField,
                          "head-variant": "dark",
                          bordered: "",
                          small: "",
                          "show-empty": "",
                          "empty-text": _vm.FormMSG(126, "No calendars found"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(calNumber)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(data.item.calNumber) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(name)",
                            fn: function (data) {
                              return [
                                !data.item.forEdit
                                  ? _c("div", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(data.item.name) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: data.item.name,
                                            callback: function ($$v) {
                                              _vm.$set(data.item, "name", $$v)
                                            },
                                            expression: "data.item.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                          {
                            key: "cell(edit)",
                            fn: function (data) {
                              return [
                                !data.item.forEdit
                                  ? _c("div", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn-transparent",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editCalendar(data.item)
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("Edit"), {
                                            tag: "component",
                                            attrs: { color: "#225CBD" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _c("div", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn-transparent",
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateCalendar(
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("CheckSquare"), {
                                            tag: "component",
                                            attrs: { color: "#3C7442" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "cell(remove)",
                            fn: function (data) {
                              return [
                                data.item.calNumber > 0
                                  ? _c("div", [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent text-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.delCalendar(data.item)
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("Trash2"), {
                                            tag: "component",
                                            attrs: {
                                              size: 20,
                                              "stroke-width": 2,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }